<template>
  <div class="container">
    <!-- 循环遍历所有店铺 -->
    <h2>{{ currentDate }}</h2>
    <div v-for="(store, storeIndex) in stores" :key="storeIndex">
      <div class="header">
        <h2>{{ store.storeName }}</h2>
      </div>
      <!-- 类别总览 -->
      <el-row :gutter="20">
        <el-col :span="8" v-for="(category, index) in store.categoryTotals" :key="index">
          <div class="grid-content">
            <div class="category-name">{{ category.productCategory }}</div>
            <div class="category-info">
              预估实收 {{ category.incomeFee }}
            </div>
          </div>
        </el-col>
      </el-row>

    </div>
  </div>
</template>
<script>
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}

import { ref } from 'vue'
import { ElLoading } from 'element-plus'
export const API_BASE_URL = 'http://bakeapi.sean.date';
// export const API_BASE_URL = 'http://127.0.0.1:7667';

export default {
  setup() {
    const stores = ref([]);
    const currentDate = ref('');
    const loadingInstance = ElLoading.service({fullscreen: true});

    const fetchData = async () => {
      try {
        // 并行发送 API 请求
        const [categoryResponse, dataResponse] = await Promise.all([
          fetch(API_BASE_URL + '/category_totals'),
          fetch(API_BASE_URL + '/get_data_json')
        ]);

        if (categoryResponse.ok && dataResponse.ok) {
          const categoryJson = await categoryResponse.json();
          const dataJson = await dataResponse.json();
          
          // 处理并存储获取的数据
          stores.value = categoryJson.map(store => ({
            storeName: store.storeName,
            categoryTotals: store.categoryTotals,
            data: dataJson.find(data => data.storeName === store.storeName).data,
            current_date: store.current_date
          }));
          currentDate.value = categoryJson[0].current_date;
        } else {
          console.error('Failed to load data:', categoryResponse.status, dataResponse.status);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        loadingInstance.close();
      }
    }

    // 初始化页面数据
    fetchData();
    
    return { currentDate, stores }
  }
}
</script>

<style scoped>
.container {
  padding: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-container, .el-row {
  margin-bottom: 20px;
}

.table-title, .category-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.category-info {
  font-size: 14px;
  color: #666;
}

.grid-content {
  background-color: #fadf9b;
  border-radius: 4px;
  margin-top: 15px;
  padding: 15px;
}

.el-col {
  border-radius: 4px;
}
</style>
